import React from "react";
import Form from "../multi-form/Form";
import * as classes from "./demo.module.scss";
import { useTranslation } from 'react-i18next';

const Demo = () => {
  const {t} = useTranslation();
  return (
    <>
      {" "}
      <div className={`conatiner font-sans`}>
        <div
          className={`text-white bg-cover bg-no-repeat h-full md:pb-10 `}
          style={{ backgroundImage: `url("https://d2lxkizvrhu4im.cloudfront.net/images/bg1.svg")` }}
        >
          <div className=" flex flex-col lg:flex-row justify-between  items-center  w-11/12 md:w-10/12  mx-auto pt-10 sm:pt-0 md:py-10 :py-14 md:space-x-6">
            <div
              className={`w-full lg:w-5/12  mx-auto md:mx-0  md:space-y-4 flex flex-col items-start `}
            >
              <div
                className={`md:font-bold font-bold text-3xl mt-8 md:mt-0 md:text-6xl text-left leading-loose  ${classes.title}`}
              >
                <h1>{t('request_your_mereos_demo')}</h1>
              </div>
              <div className="block">
                <div
                  className={`text-sm md:text-xl md:w-9/12 font-medium text-left  leading-loose ${classes.description}`}
                >
                  <p className='text-justify'>{t('our_sales_team_would_be_delighted')}</p>
                  {t('in_30_minutes_discover')} <br />
                </div>
                <div className={`w-full flex justify-start`}>
                  <nav className="list-none text-sm font-medium space-y-2 md:space-y-8 md:text-base my-7">
                    <li className="flex flex-row space-x-4 items-center">
                      <img
                        alt="tick icon"
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/white_tick_icon.svg"
                        className="h-6 m-0"
                      />{" "}
                      <span>{t('our_different_products_and_services')} </span>
                    </li>
                    <li className="flex flex-row space-x-4 items-center">
                      <img
                        alt="tick icon"
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/white_tick_icon.svg"
                        className="h-6 m-0"
                      />{" "}
                      <span>
                        {t('our_security_and_exclusive_features')}
                      </span>
                    </li>
                    <li className="flex flex-row space-x-4 items-center">
                      <img
                        alt="tick icon"
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/white_tick_icon.svg"
                        className="h-6 m-0"
                      />
                      <span>
                        {t('how_to_run_an_assessment')}
                      </span>
                    </li>
                    <li className="flex flex-row space-x-4 items-center">
                      <img
                        alt="tick icon"
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/white_tick_icon.svg"
                        className="h-6 m-0"
                      />
                      <span>
                        {t('the_experience_lived')}
                      </span>
                    </li>
                    {/* <li className="flex flex-row space-x-4 items-center">
                      <img
                        alt="tick icon"
                        src="https://d2lxkizvrhu4im.cloudfront.net/icons/white_tick_icon.svg"
                        className="h-6 m-0"
                      />
                      <span>
                        Notre dispositif pour une protection des données sans
                        faille
                      </span>
                    </li> */}
                  </nav>
                </div>
              </div>
            </div>
            <div
              className={`flex flex-col items-center justify-start  w-full lg:w-7/12`}
            >
              <Form />
            </div>
          </div>
        </div>

        <div className="w-11/12 md:w-10/12 mx-auto my-10 md:mt-20 md:mb-28">
          <div className="flex flex-col-reverse md:flex-row justify-between items-center md:mb-10  md:space-y-0 ">
            <div
              className="w-full md:w-5/12 rounded-xl p-4 md:p-9 mt-10 md:mt-0 flex flex-col justify-between"
              style={{ backgroundImage: `url("https://d2lxkizvrhu4im.cloudfront.net/images/card_bakground.svg")` }}
            >
              <div className="flex flex-row justify-between">
                <div className="">
                  <div className="font-bold text-white text-xl md:text-2xl">
                    Catherine Gautier
                  </div>
                  <div className="text-white text-sm md:text-lg font-extralight">
                    Déléguée générale du concours Passerelle
                  </div>
                  {/* <div className="flex flex-row space-x-4 ">
                    <img
                      alt="users icon"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/white_users_icon.svg"
                      className=""
                    />
                    <span className="text-white">14 employees</span>
                  </div> */}
                </div>
                <div>
                  <img
                    src="https://mereos-dashboard.s3.eu-west-3.amazonaws.com/images/Capture+d%E2%80%99%C3%A9cran+2024-09-17+%C3%A0+09.56.52.png"
                    className="h-20 rounded-full border-2	border-solid border-white	"
                    alt="profile"
                  />
                </div>
              </div>

              <div className=" text-white text-sm md:text-lg text-justify">
                {t('mereos_gave_us_the_autonomy')}
              </div>
            </div>
            <div className="w-full md:w-5/12  text-lg font-bold space-y-8 ">
              <div className="text-3xl md:text-5xl font-semibold text-center md:text-left">
                {t('everything_is_possible')}
              </div>
              <div className="flex flex-row space-x-4 items-center ">
                <img
                  alt="users icon"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/globe_icon_with_bg.svg"
                  className="my-0 h-10 lg:h-16"
                />
                <div className="flex flex-col space-y-2 md:w-2/3">
                  <div className="font-semibold text-lg md:text-xl">
                    {t('no_geographical_barrier')}
                  </div>
                  <div className="font-medium text-sm md:text-base text-gray-500">
                    {t('dont_limit_yourself_in_your_search')}
                  </div>
                </div>
              </div>

              <div className="flex flex-row space-x-4 items-center ">
                <img
                  alt="eyes icon"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/wall_icon_with_bg.svg"
                  className="my-0 h-10 lg:h-16"
                />
                <div className="flex flex-col space-y-2  md:w-2/3">
                  <div className="font-semibold text-lg md:text-xl">
                    {t('no_fraud_tolerated')}
                  </div>
                  <div className="font-medium text-sm md:text-base text-gray-500">
                    {t('only_recruit_competent')}
                  </div>
                </div>
              </div>

              <div className="flex flex-row space-x-4 items-center ">
                <img
                  alt="graph icon"
                  src="https://d2lxkizvrhu4im.cloudfront.net/icons/shield_icon_with_bg.svg"
                  className="my-0 h-10 lg:h-16"
                />
                <div className="flex flex-col space-y-2  md:w-2/3">
                  <div className="font-semibold text-lg md:text-xl">
                    100% {t('secure')}
                  </div>
                  <div className="font-medium text-sm md:text-base text-gray-500">
                   {t('data_protection_is_at_the_heart')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Demo;
